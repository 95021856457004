/**
 * getEventComposedPath
 *  compatibility fixed for Event.path/Event.composedPath
 *  Event.path is only for chrome/opera
 *  Event.composedPath is for Safari, FF
 *  Neither for Micro Edge
 * @param {Event} evt
 * @return {Array} an array of event.path
 */
export function getEventComposedPath (evt) {
  let path
  // chrome, opera, safari, firefox
  path = evt.path || (evt.composedPath && evt.composedPath())

  // other: edge
  if (path === undefined && evt.target) {
    path = []
    let target = evt.target
    path.push(target)

    while (target && target.parentNode) {
      target = target.parentNode
      path.push(target)
    }
  }

  return path
}

/**
 * tex转svg
 * @param {String} texsetAttribute(
 * @param {Boolean?} block
 * @param {Element?} stage
 * @return {Node} SVG对象
 */
export function texRender (tex, block = false, stage = undefined) {
  const MathJax = window.MathJax
  MathJax.texReset()
  const options = {
    em: 14,
    ex: 7.6,
    containerWidth: 790,
    lineWidth: 1000000,
    scale: 1,
    family: '',
    display: block,
  }
  if (stage) {
    Object.assign(options, MathJax.getMetricsFor(stage))
  }
  // MathJax.tex2svgPromise(tex, options).then(node => {})
  try {
    const mjxContainer = MathJax.tex2svg(tex, options)
    mjxContainer.dataset.latex = tex
    // MathJax.startup.document.clear()
    // MathJax.startup.document.updateDocument()
    return mjxContainer
  } catch (e) {
    console.error('texRender error:', e)
    throw e
  }
}

export function transform (el) {
  const list = el.childNodes
  for (let i = list.length - 1; i >= 0; i--) {
    const node = list[i]
    if (node.nodeType === 3) {
      const d = []
      // /(.*?)\\\((.+?)\\\)/g
      const last = node.data.replace(/([\s\S]*?)(\\\([\s\S]*?\\\)|\\\[[\s\S]*?\\])/g, function (m, $1, $2) {
        if ($1.length) {
          d.push(document.createTextNode($1))
        }
        // const mjx = document.createElement('mjx')
        // mjx.dataset.latex = $2

        const tex = $2.substring(2, $2.length - 2)
        const mjx = texRender(tex, $2.startsWith('\\['))
        d.push(mjx)
        return ''
      })
      if (d.length) {
        if (last) {
          d.push(document.createTextNode(last))
        }
        // 替换元素
        d.forEach(m => {
          el.insertBefore(m, node)
        })
        el.removeChild(node)
      }
    } else {
      transform(node)
    }
  }
  return el
}

export function quxRender (ctx) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(ctx, 'text/html')
  return transform(doc.body)
}
